<script>
    import toast_ from 'svelte-french-toast';
    import { goto } from '$app/navigation';

    export let toast;

    // Use this component in your app:
    // toast(RichContent)
</script>

<div class='flex flex-row justify-center items-center gap-5'>
    You must be logged in to do that.
	<button class='btn btn-sm variant-filled-primary rounded-lg' on:click={() => {
        toast_.dismiss(toast.id);
        goto('/login');
	}}>Log In</button>
</div>